<div class="auth-main particles_js ">
    <div class="auth_div vivify popIn">
        <div class="auth_brand">
            <!-- <a class="navbar-brand" href="javascript:void(0);">
                <img src="./../../assets/logo.JPEG" width="120px" height="" class="d-inline-block align-top mr-2" alt=""></a> -->
        </div>
        <div class="card ">

            <div class="body">
                <div class="text-center">
                    <img src="" class="logo"><br>
                    <img src="./../../assets/logo1.png" class="text-img">
                </div>
                <p class="lead">Administrator</p>
                <form class="form-auth-small m-t-20" #loginForm="ngForm" (ngSubmit)="loginForm.form.valid && onSubmit(loginForm)">
                    <div class="form-group">
                        <label for="email" class="control-label sr-only">Email</label>
                        <input type="email" class="form-control round" name="email" #email="ngModel" [(ngModel)]="model.email" required
                            placeholder="Email" [ngClass]="{ 'is-invalid': loginForm.submitted && email.invalid }"
                            required>
                        <div class="invalid-feedback" *ngIf="loginForm.submitted && email.invalid">
                            <p *ngIf="email.errors?.required">Email is required</p>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password" class="control-label sr-only">Password</label>
                        <input [type]="show ? 'text' : 'password'" class="form-control round" name="password" #password="ngModel" [(ngModel)]="model.password" required
                            placeholder="Password" [ngClass]="{ 'is-invalid': loginForm.submitted && password.invalid }"
                            required>
                            <i *ngIf="show" class="fa fa-eye" (click)="isToggle()" id="togglePassword"></i>
                            <i *ngIf="!show" class="fa fa-eye-slash" (click)="isToggle()" id="togglePassword"></i>
                            <!-- <i ></i> -->
                        <div class="invalid-feedback" *ngIf="loginForm.submitted && password.invalid">
                            <p *ngIf="password.errors?.required">Password is required</p>
                        </div>
                    </div>
                    <!-- <div class="form-group clearfix">
                        <label class="fancy-checkbox element-left">
                            <input type="checkbox">
                            <span>Remember me</span>
                        </label>								
                    </div> -->
                    <button *ngIf="!isLoading" type="submit" class="btn btn-primary btn-round btn-block">LOGIN</button>
                    <button *ngIf="isLoading" disabled class="btn btn-primary btn-round btn-block">PLEASE WAIT</button>

                    <hr>
                </form>
            </div>
        </div>
    </div>
    <div id="particles-js"></div>
</div>