<div id="main-content">
    <div class="container-fluid">
        <div class="block-header">
            <div class="row clearfix">
            </div>
        </div>
        <div class="body ">
            <div class="card">
                <img src="./../../assets/logo/coming-soon-page.jpg" width="100%">
            </div>
        </div>
    </div>
</div>
