import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../Auth/auth.service';
import { ApiService } from '../_api/api.service';

export class User {
  public email!: string;
  public password!: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  show: boolean = false;

  model: any = new User();
  isSubmitted = false;
  isLoading: boolean = false;
  constructor(
    private _api: ApiService,
    private _as: AuthService,
    private _toast: ToastrService,
    private _route: Router
  ) {
  }

  ngOnInit(): void {
  }
  // click event function toggle
  isToggle() {
    this.show = !this.show;
  }
  onSubmit(form: any) {
    // this._route.navigate(['/admin'])
    console.log(form.value)
    this.isLoading = true;


    this._api.isLogin(form.value).subscribe((res: any) => {
      console.log(res)
      this.isLoading = false;
      if (res.status == 200) {
        this._toast.success(res.result.message, "Success")
        localStorage.setItem('token', res.result.token)
        this._as.sendToken(res.result.token)
        this._route.navigate(['/admin']).then(() => {
          window.location.reload();
        });
      }
      if (res.status == 404) {
        this._toast.error(res.result.message, "!Error")
      }
    }, (err: any) => {
      this.isLoading = false;
      this._toast.error(err, "!Error")
    })
  }
}
