import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // private _BaseUrl = "http://13.234.204.118:5000/AdminApp"
  // private _BaseUrl = "http://3.109.1.110:8486"
  private _BaseUrl = "https://un.metalsart.in/AdminApp"
  // private URL = "http://127.0.0.1:8000/AdminApp"
  token: any

  constructor(private _http: HttpClient) {
    this.token = localStorage.getItem('isLoogedIn')
    console.log(this.token)

  }



  // '1' *******Login /

  isLogin(data: any) {

    return this._http.post(`${this._BaseUrl}/TeamLoginAPI/`, data)
      .pipe(catchError(this.errorHandler))

  }
  getActiveStudentList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/StudentListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //3 SuperAdminAPI Active List Api
  getExamTypeByIdList(courseId:number) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ExamListByCourseIdAPI/?courseId=`+ courseId,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


   //3 SuperAdminAPI Active List Api
   getSubjectByIdList(courseId:number) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SubjectByCourseIdAPI/?courseId=`+ courseId,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveExamTypeList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ExamTypeAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //2  SuperAdminAPI Add  API
  superAdminAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SuperAdminAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveSuperAdminList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SuperAdminAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveSuperAdminList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SuperAdminAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteSuperAdmin(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/SuperAdminDeleteAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  superAdminData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SuperAdminAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateSuperAdmin(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SuperAdminAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }


  //2  SuperAdminAPI Add  API
  teamAdminAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/TeamAdminAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveTeamAdminList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TeamAdminAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveTeamAdminList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TeamAdminAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteTeamAdmin(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/TeamAdminAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  teamAdminData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TeamAdminAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateTeamAdmin(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/TeamAdminAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }


















  /** Error Handling method */


  //2  SuperAdminAPI Add  API
  courseAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/CourseAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveCourseList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/CourseAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveCourseList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/CourseAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteCourse(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/CourseAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  courseData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/CourseAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateCourse(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/CourseAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }




  //2  SuperAdminAPI Add  API
  subjectAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SubjectAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveSubjectList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SubjectGetAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveSubjectList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SubjectGetAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteSubject(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/SubjectAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  subjectData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SubjectAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateSubject(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SubjectAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }






  //2  SuperAdminAPI Add  API
  adminAdvertiseAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SuperAdminAdvertiseAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveAdminAdvertiseList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SuperAdminAdvertiseAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveAdminAdvertiseList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SuperAdminAdvertiseAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteAdminAdvertise(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/SuperAdminAdvertiseAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  adminAdvertisetData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SuperAdminAdvertiseAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateAdminAdvertise(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SuperAdminAdvertiseAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }






  //2  SuperAdminAPI Add  API
  teamAdvertiseAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/TeamAdvertiseAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveTeamAdvertiseList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TeamAdvertiseActiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActivTeamAdvertiseList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TeamAdvertiseInActiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteTeamAdvertise(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/SuperAdminAdvertiseAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  teamAdvertisetData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TeamAdvertiseByIdListAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateTeamAdvertise(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/TeamAdvertiseAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }




  //2  SuperAdminAPI Add  API
  studyMaterialAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/StudyMaterialAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveStudyMaterialList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/StudyMaterialActiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveStudyMaterialList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/StudyMaterialInActiveListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteStudyMaterial(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/SuperAdminAdvertiseAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  studyMaterialData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/StudyMaterialByIdListAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }

  //2  SuperAdminAPI Add  API
  studyMaterialUpdate(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/StudyMaterialAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  // //7 ****SuperAdminAPI Update Api
  // updateStudyMaterial(data: any) {
  //   const optHeader = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': this.token
  //   });
  //   return this._http.post(`${this._BaseUrl}/StudyMaterialAPI/`, data,
  //     {
  //       headers: optHeader
  //     })
  //     .pipe(catchError(this.errorHandler))

  // }


  //2  SuperAdminAPI Add  API
  testAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/TestAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveTestList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TestAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveTestList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TestAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteTest(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/TestAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  testData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TestListByIdAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateTest(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/TestAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }

  //2  SuperAdminAPI Add  API
  testDetailsAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/TestDetailAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveTestDetailsList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TestDetailAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveTestDetailsList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TestDetailAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteTestDetails(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/TestDetailAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  testDetailsData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TestDetailAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateTestDetails(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/TestDetailAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }











  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
