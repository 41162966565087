import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGuard } from './Auth/user.guard';
import { CommingSoonComponent } from './comming-soon/comming-soon.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [

  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('./User/user/user.module').then(a => a.UserModule)
    ,
    // canActivate : [UserGuard]
  },
  {
    path: '**',
    component: NotFoundComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
